<template>
    <recess-modal :show-modal="isModalVisible" @close="close">
        <template slot="modal-title">
            <h3 class="qa-confirm-legal-company-deletion-title"> {{ SHARED.LegalCompany.Popup.Delete.Title }} </h3>
        </template>
        <template slot="modal-body"> 
            <p class="qa-confirm-legal-company-deletion-description">
                {{ SHARED.LegalCompany.Popup.Delete.DescriptionPart1 }} 
                
                <a :href="`mailto:${SHARED.LegalCompany.Popup.Delete.Email}`">
                    {{ SHARED.LegalCompany.Popup.Delete.Email }} 
                </a>
                
                {{ SHARED.LegalCompany.Popup.Delete.DescriptionPart2 }}
            </p> 
            

        </template>
        <template slot="modal-footer">
            <div class="d-flex justify-content-end mt-5">
                <recess-button
                    variant="secondary"
                    :title="BUTTON_TEXT.delete"
                    class="mr-2 qa-confirm-legal-company-deletion-button" 
                    @click.prevent.native="deleteLegalCompany()"
                />
            </div>
        </template>
    </recess-modal>
</template>

<script>
import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { SHARED } from "../../constants/EdumsSharedConstants.json"
import { deleteItem } from '@/../../shared/api/SharedClient'
import { defaultErrorMessage, legalCompanySuccessAction } from '../../constants/validationMessageHelper'

export default {
    name: 'DeleteLegalCompanyPopup',
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        },
        legalCompanyIdToDelete: {
            type: String,
            default: null
        },
        apiBaseUrl: {
            type: String,
            default: null
        }

    },
    data() {
        return {
            SHARED,
            BUTTON_TEXT
        }
    },
    methods: {
        close() { 
            this.$emit('close')
        }, 
        async deleteLegalCompany() {
            if (!this.legalCompanyIdToDelete) return 

            await deleteItem(
                this.apiBaseUrl,
                `${API_CALL_URL_PATHS.legalCompanies}/${this.legalCompanyIdToDelete}`,
                false
            )
            .then(() => {
                const toastNotification = {
                    type: "success",
                    message: legalCompanySuccessAction('verwijderd')
                };

                this.$store.dispatch("toastNotificationModule/add", toastNotification, {
                    root: true,
                }); 

                this.$emit('deleteTriggered', this.legalCompanyIdToDelete) 
                this.close()   
            })
            .catch((error) => {
                const toastNotification = {
                    type: 'error',
                    message: defaultErrorMessage
                }

                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })

                console.error('Something went wrong while deleting Legal Company', error)
            })
        }
         
    }
}
</script>